import { useAuthStore } from '~/stores/auth.store';
import { useGameStore } from '~/stores/games.store';
import { useSiteStore } from '~/stores/site.store';
import { useServices } from '~/composables/useServices';
export default async function ({ game, skipRouting = false }): Promise<void> {
  const { $gameService } = useServices();

  const router = useRouter();
  const route = useRoute();
  const auth = useAuthStore();
  const games = useGameStore();
  const settings = useSiteStore();
  const { name, id, categories } = game;
  const cats: string[] = Object.keys(categories || {});
  if (auth.loggedIn) {
    if (
      auth.user?.compliance?.complianceStatus <
      auth.user?.compliance?.requiredComplianceStatus
    ) {
      auth.setAccountModalPage('document-verification');
      return;
    }
    game = await $gameService.fetchGame({
      gameId: +game.id,
      regionCode: settings.getRegionCode,
      vertical: String(route.params.contentType),
      channel: 'webdesktop',
      count: 1,
      languageCode: 'en-US',
      currency: 'USD',
    });
    try {
      if (settings.modalActive) {
        settings.toggleFeature({ feature: 'displaySearch' });
        settings.deactivateModal();
      }
      if (!skipRouting)
        if (!!route.params?.category) {
          router.push(
            `/${route.params.contentType || 'home'}/${route.params.category}/${
              game.alias
            }`
          );
        } else {
          router.push(
            `/${route.params.contentType || 'home'}/${cats[0].toLowerCase()}/${
              game.alias
            }`
          );
        }
    } catch (e) {
      console.error(e);
      if (!route.query?.id) settings.toggleFullscreenLoading();
      settings.toggleSiteNotice({
        heading: 'Error',
        message: `Could not launch ${name}, please try again later.`,
        severity: 'error',
        enabled: true,
        timeout: 15000,
      });
    }
  } else {
    settings.activateModal('login');
    games.setCurrentGame(game);
    await router.push({
      query: {
        redirect: `/${route.params.contentType || 'home'}/${
          route.params.category || cats[0].toLowerCase()
        }/${game.alias}`,
      },
    });
  }
}
