<script setup lang="ts">
import { useAuthStore } from '~/stores/auth.store';
import Button from '~/components/design-system/button.vue';
import { useGameStore } from '~/stores/games.store';
import { trackingMethods } from '~~/plugins/analytics.client';
import useLaunchGame from '../../composables/useLaunchGame';
import { useSiteStore } from '~/stores/site.store';
import { ThumbUpIcon } from '@heroicons/vue/solid/index.js';
import { useFormatNumberCompact } from '../../composables/useFormatters';

const auth = useAuthStore();
const games = useGameStore();
const launchGameComp = useLaunchGame;
const lazyImage = useLazyImage;
const image = ref();

const { $t } = useNuxtApp();
const settings = useSiteStore();

interface CardProps {
  id?: any;
  image?: string;
  title?: string;
  subtitle?: string;
  badgeRight?: string | number;
  badgeLeft?: string | number;
  game?: any;
  skeleton?: boolean;
  vertical?: boolean;
  minBetAmount?: string;
}

const props = withDefaults(defineProps<CardProps>(), {
  id: 0,
  image: '',
  title: '',
  subtitle: '',
  game: {},
  skeleton: false,
  vertical: false,
  minBetAmount: '',
});

const isFavorite: globalThis.ComputedRef<boolean> = computed(() => {
  return games.returnFavoriteIds.some((f) => f === props.id);
});

function favoriteClicked(e: Event) {
  e.stopPropagation();
  if (auth.isLoggedIn) {
    isFavorite.value ? removeFavorite(props.game) : addFavorite(props.game);
  } else {
    settings.activateModal('login');
  }
}

function addFavorite(game: any) {
  useAddFavorites({ game });
  // send GA tracking method when game favourited
  trackingMethods.favouriteGameAdd(game.name, auth.currentUser.username);
}

function removeFavorite(game: any) {
  useRemoveFavorites({ game });

  // send GA tracking method when game unfavourited
  trackingMethods.favouriteGameRemove(game.name, auth.currentUser.username);
}

const card = ref();
const render = ref(true);
const cardHolster = ref();

function launchGame(game: any) {
  if (game.unavailable) return;
  launchGameComp({ game });

  // send GA tracking method when game is launched
  trackingMethods.launchReal(game.name, auth.currentUser.username);
}

onMounted(() => {
  if (!props.skeleton)
    nextTick(() => {
      lazyImage(image.value, props.image);
    });
});
</script>
<template>
  <div class="w-full">
    <div
      v-if="!vertical"
      ref="cardHolster"
      class="text-dark-700 dark:text-white cursor-pointer w-full"
      @click="launchGame(props.game)"
      style="aspect-ratio: 153/202"
    >
      <Card
        v-if="render"
        :class="[game.unavailable ? 'unavailable' : '']"
        ref="card"
        class="game-card"
      >
        <template #header>
          <div
            class="border-round-top-lg w-full justify-content-center relative"
            style="margin-bottom: -3px"
          >
            <div
              v-show="!props.skeleton"
              class="bg-white dark:bg-dark-800 absolute hover:cursor-pointer border-round-md"
              style="z-index: 1; right: 2px; top: 2px; padding: 2px 4px"
              @click="favoriteClicked"
            >
              <i
                class="text-xl pi pi-heart-fill"
                :class="
                  isFavorite
                    ? 'blue-gradient-text'
                    : 'text-light-900 dark:text-dark-400'
                "
              />
            </div>

            <div
              v-if="game.unavailable"
              class="maintenance absolute h-full w-full flex justify-content-center align-items-center z-1 dark:text-white text-black"
            >
              <div
                class="flex align-items-center dark:bg-dark-800 bg-white p-1 border-round text-xs md:text-normal"
              >
                <div
                  class="gold-dot flex justify-content-center align-items-center relative mr-1 text-xs"
                >
                  {{ '' }}
                </div>
                <strong>
                  {{ $t('in-maintenance') }}
                </strong>
              </div>
            </div>
            <img
              ref="image"
              class="border-round-lg w-full justify-content-center overflow-hidden relative fit-cover"
              style="aspect-ratio: 1/1"
              :src="
                useImagePath(
                  '/medialibraries/Jackpotcity.co.za/content-pages/placeholders/JPC_GameTilePlaceHolder_500x500.jpg'
                )
              "
              :alt="props.title"
              :id="props.id"
              :width="card?.scrollWidth"
              :height="card?.scrollHeight"
            />
          </div>
        </template>
        <template #content>
          <div
            class="w-max-full border-round-bottom-lg overflow-hidden bg-transparent"
          >
            <div class="p-1 overflow-x-hidden w-full">
              <div
                class="text-xs text-black dark:text-white font-bold text-left w-full overflow-hidden hover:cursor-pointer truncate line-height-1 text-content"
              >
                <Skeleton v-if="props.skeleton" class="h-2 dark:bg-dark-500" />
                <span v-else>{{ props.title || ' ' }}</span>
              </div>
              <div
                class="likes-bet flex justify-content-between text-xs text-black dark:text-white truncate w-full capitalize text-left line-height-1 text-content"
              >
                <Skeleton v-if="props.skeleton" class="h-2" width="75%" />
                <span class="mt-1">
                  <span
                    class="minBetAmount"
                    :style="{ opacity: !!props?.game?.minBetAmount ? 1 : 0 }"
                  >
                    {{ props?.game?.minBetAmount || 0 }}
                  </span>
                </span>
                <span v-if="props?.game?.likesCount">
                  <ThumbUpIcon style="margin-bottom: -2px" :class="`w-4`" />
                  {{ useFormatNumberCompact(props?.game?.likesCount) }}
                </span>
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>
    <div v-else ref="cardHolster" @click="launchGame(props.game)">
      <Card
        v-if="render"
        :class="[game.unavailable ? 'unavailable' : '']"
        ref="card"
        class="game-card"
      >
        <template #header>
          <div
            class="border-round-top-lg w-full justify-content-center overflow-hidden relative"
            style="margin-bottom: -3px"
          >
            <div
              class="flex justify-content-between w-full text-white absolute text-red header-top-item p-2 pt-1 pl-1 pr-1"
            >
              <!-- (badgeLR + '').length - type number -->
              <Button
                v-if="(props.badgeLeft + '').length > 0"
                group="primary"
                class="text-xs font-medium border-round-sm"
                size="small"
              >
                {{ props.badgeLeft }}
              </Button>
              <Button
                v-if="(props.badgeRight + '').length > 0"
                group="primary-gold"
                class="text-xs font-medium"
                size="small"
              >
                {{ props.badgeRight }}
              </Button>
            </div>
            <div
              v-if="game.unavailable"
              class="maintenance absolute h-full w-full flex justify-content-center align-items-center z-1 dark:text-white text-black"
            >
              <div
                class="flex align-items-center dark:bg-dark-800 bg-white p-1 border-round"
              >
                <div
                  class="gold-dot flex justify-content-center align-items-center relative mr-1 text-xs"
                >
                  {{ '' }}
                </div>
                <strong>
                  {{ $t('in-maintenance') }}
                </strong>
              </div>
            </div>
            <img
              ref="image"
              class="border-round-top-lg w-full justify-content-center overflow-hidden relative fit-cover"
              style="aspect-ratio: 1/2"
              src="https://cms1.jpc.africa/medialibraries/Jackpotcity.co.za/content-pages/placeholders/JPC_GameTilePlaceHolder_500x500.jpg"
              :alt="props.title"
              :id="props.id"
              :width="card?.scrollWidth"
              :height="card?.scrollHeight"
            />

            <div
              class="pl-1 pr-1 b-0 b-right-5 dark:bg-dark-800 cursor-pointer static translate-y-0 flex text-black dark:text-white bottom-right bg-light-50 dark:b-dark-500 border-round-lg mr-1"
            >
              <div
                v-if="props?.game?.likesCount"
                class="likes dark:bg-dark-800 text-black dark:text-white bg-light-50 dark:bg-dark-500"
                style="z-index: 1"
              >
                {{
                  props?.game?.likesCount > 999
                    ? Math.floor(props?.game?.likesCount / 100) / 10.0 + 'K'
                    : props?.game?.likesCount
                }}
                Likes
              </div>
            </div>
          </div>
        </template>
        <template #content></template>
      </Card>
    </div>
  </div>
</template>

<style scoped>
.gold-dot {
  width: 12px;
  height: 12px;
}
.gold-dot::before {
  content: ' ';
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-image: linear-gradient(45deg, #d87700 0%, #ffc018);
}

.gold-dot::after {
  content: ' ';
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  opacity: 0.25;
  background-color: #ffc018;
}
.dark .maintenance {
  background-color: #1d2129c9;
}
.maintenance {
  background-color: #ffffffc9;
}

.game-card:hover:not(.unavailable) img {
  transition: box-shadow ease-in-out 300ms;
  box-shadow: 0 8px 8px 4px rgba(56, 121, 251, 0.3);
}

.game-card.unavailable:hover,
.game-card.unavailable {
  cursor: not-allowed;
  pointer-events: none;
}

.game-card:not(:hover),
.game-card.unavailable {
  box-shadow: unset;
}

.header-top-item button {
  padding: 3.5px 5px !important;
  height: 20px !important;
}

.container {
  position: relative;
  text-align: center;
  color: white;
}
.bottom-right {
  position: absolute !important;
  bottom: 8px;
  right: 0px;
  justify-content: end;
}

.likes {
  padding: 2px;
  border-radius: 5px;
  font-size: 0.75rem;
}

.text-content {
  line-height: 12px !important;
  padding: 2px !important;
}

@media screen and (max-width: 1024px) {
  .header-top-item button {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 767px) {
  .likes-bet {
    font-size: 0.7rem !important;
  }
}
</style>
